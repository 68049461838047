import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import Client from '../utils/network';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  Input,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableRow,
} from './elements/Elements';
import SalesRow from './SalesRow';
import TableSkeleton from './skeletons/TableSkeleton';

const MAX_RESULTS = 999;

const Sales = () => {
  const [fuse, setFuse] = useState();
  const [initial, setInitial] = useState();
  const [filtered, setFiltered] = useState();

  useEffect(() => {
    (async () => {
      try {
        const sales = await Client.get('/getSales');

        setInitial(sales);
        setFiltered(sales.slice(0, MAX_RESULTS));

        setFuse(
          new Fuse(sales, {
            keys: ['customer.name', 'customer.email', 'paymentMethod'],
            threshold: 0.5,
          }),
        );
      } catch (error) {
        toast.error(error);
      }
    })();
  }, []);

  const search = ({ target: { value } }) => {
    if (!value) return setFiltered(initial.slice(0, MAX_RESULTS));
    return setFiltered(
      fuse
        .search(value)
        ?.map(({ item }) => item)
        .slice(0, MAX_RESULTS),
    );
  };

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Sales</H1>
        </FlexItem>
        <FlexItem marginAuto alignRight>
          <Button to="/sales/create">Create Sale</Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem marginAuto>
          <Input
            style={{ maxWidth: '400px' }}
            name="search"
            type="text"
            placeholder="Type to search"
            onChange={search}
          />
        </FlexItem>
        <FlexItem marginAuto alignRight />
      </FlexContainer>
      {filtered ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Date</TableHeadItem>
              <TableHeadItem>Customer</TableHeadItem>
              <TableHeadItem>Method</TableHeadItem>
              <TableHeadItem>Status</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Total Price
              </TableHeadItem>
              <TableHeadItem />
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.map((sale) => (
              <SalesRow key={sale._id} sale={sale} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

export default Sales;

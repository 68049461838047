import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import Client from '../utils/network';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  Input,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableRow,
} from './elements/Elements';
import WriteOffsRow from './WriteOffsRow';
import TableSkeleton from './skeletons/TableSkeleton';

const MAX_RESULTS = 999;

const Customers = () => {
  const [fuse, setFuse] = useState();
  const [initial, setInitial] = useState();
  const [filtered, setFiltered] = useState();

  useEffect(() => {
    (async () => {
      try {
        const writeOffs = await Client.get('/getWriteOffs');

        setInitial(writeOffs);
        setFiltered(writeOffs.slice(0, MAX_RESULTS));

        setFuse(
          new Fuse(writeOffs, {
            keys: ['totalCost', 'notes'],
            threshold: 0.5,
          }),
        );
      } catch (error) {
        toast.error(error);
      }
    })();
  }, []);

  const search = ({ target: { value } }) => {
    if (!value) return setFiltered(initial.slice(0, MAX_RESULTS));
    return setFiltered(
      fuse
        .search(value)
        ?.map(({ item }) => item)
        .slice(0, MAX_RESULTS),
    );
  };

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Write Offs</H1>
        </FlexItem>
        <FlexItem marginAuto alignRight>
          <Button to="/write-offs/create">Create Write Off</Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem marginAuto>
          <Input
            style={{ maxWidth: '400px' }}
            name="search"
            type="text"
            placeholder="Type to search"
            onChange={search}
          />
        </FlexItem>
        <FlexItem marginAuto alignRight />
      </FlexContainer>
      {filtered ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Date</TableHeadItem>
              <TableHeadItem>Notes</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Total Cost
              </TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.map((writeOff) => (
              <WriteOffsRow key={writeOff._id} writeOff={writeOff} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

export default Customers;

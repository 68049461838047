import create from 'zustand';
import { PRODUCT_FILTERS_STORAGE_KEY } from '../constants';

const DEFAULT_FILTERS = {
  unsold: true,
  sold: true,
  writtenOff: false,
};

const useFilters = create((set) => ({
  productFilters:
    JSON.parse(localStorage.getItem(PRODUCT_FILTERS_STORAGE_KEY)) ||
    DEFAULT_FILTERS,
  setProductFilters: (productFilters) => {
    localStorage.setItem(
      PRODUCT_FILTERS_STORAGE_KEY,
      JSON.stringify(productFilters),
    );
    set(() => ({ productFilters }));
  },
}));

export default useFilters;

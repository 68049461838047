import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import { humanReadableDollars } from '../utils/helpers';
import Client from '../utils/network';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  Input,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableItem,
  TableRow,
} from './elements/Elements';
import TableSkeleton from './skeletons/TableSkeleton';

const MAX_RESULTS = 999;

const Expenses = () => {
  const [fuse, setFuse] = useState();
  const [initial, setInitial] = useState();
  const [filtered, setFiltered] = useState();

  useEffect(() => {
    (async () => {
      try {
        const expenses = await Client.get('/getExpenses');

        setInitial(expenses);
        setFiltered(expenses.slice(0, MAX_RESULTS));

        setFuse(
          new Fuse(expenses, {
            keys: ['description', 'merchant', 'category', 'notes'],
            threshold: 0.5,
          }),
        );
      } catch (error) {
        toast.error(error);
      }
    })();
  }, []);

  const search = ({ target: { value } }) => {
    if (!value) return setFiltered(initial.slice(0, MAX_RESULTS));
    return setFiltered(
      fuse
        .search(value)
        ?.map(({ item }) => item)
        .slice(0, MAX_RESULTS),
    );
  };

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Expenses</H1>
        </FlexItem>
        <FlexItem marginAuto alignRight>
          <Button to="/expenses/create">Create Expense</Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem marginAuto>
          <Input
            style={{ maxWidth: '400px' }}
            name="search"
            type="text"
            placeholder="Type to search"
            onChange={search}
          />
        </FlexItem>
        <FlexItem marginAuto alignRight />
      </FlexContainer>
      {filtered ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Date</TableHeadItem>
              <TableHeadItem>Description</TableHeadItem>
              <TableHeadItem>Merchant</TableHeadItem>
              <TableHeadItem>Category</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>Cost</TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.map(
              ({
                _id: id,
                createdAt,
                description,
                merchant,
                category,
                cost,
                generated,
              }) => (
                <TableRow key={id}>
                  <TableItem>
                    {new Intl.DateTimeFormat('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    }).format(new Date(createdAt))}
                  </TableItem>
                  <TableItem>{description}</TableItem>
                  <TableItem>{merchant}</TableItem>
                  <TableItem>{category}</TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    {humanReadableDollars(cost / 100)}
                  </TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    <Button tiny to={`/expenses/${id}`}>
                      {generated ? 'View' : 'Edit'}
                    </Button>
                  </TableItem>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

export default Expenses;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FiLock } from 'react-icons/fi';
import { Helmet } from 'react-helmet-async';
import Client from '../utils/network';
import {
  FlexContainer,
  FlexItem,
  H1,
  H2,
  HR,
  LoadingIndicator,
  P,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableItem,
  TableRow,
} from './elements/Elements';
import { humanReadableDollars } from '../utils/helpers';
import {
  STRIPE_PK,
  BUSINESS_HOME_PAGE,
  APP_CHECKOUT_METHOD,
} from '../constants';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(STRIPE_PK);

const Wrapper = styled.div`
  padding: var(--space);
`;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const CustomerName = styled.span`
  display: block;
  color: var(--gray);
`;

const Due = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.5rem;
  border-radius: var(--border-radius);
`;

const Paid = styled(Due)`
  background-color: var(--success--light);
  color: var(--success);
`;

const PaidSection = styled(Due)`
  display: block;
  padding: var(--space);
  background-color: var(--success--light);
  color: var(--success);
`;

const Secure = styled.p`
  display: flex;
  font-size: 0.85rem;
  font-weight: 300;
  color: var(--gray);
  justify-content: center;
  margin: 2rem 0;

  svg {
    margin-right: 5px;
    margin-top: 3px;
    stroke-width: 2.5px;
  }
`;

const Checkout = () => {
  const [data, setData] = useState();
  const [paid, setPaid] = useState(false);
  const { isi } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setData(await Client.get(`/getSaleForCheckout?isi=${isi}`));
      } catch (error) {
        window.location.href = BUSINESS_HOME_PAGE;
      }
    })();
  }, [isi]);

  if (!data) return <LoadingIndicator />;

  const {
    createdAt,
    products,
    customer,
    productPricesSum,
    shippingPrice,
    appliedCredit,
  } = data;

  const overdue =
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    ) > new Date(createdAt);

  return (
    <Wrapper>
      <Container>
        <Helmet>
          <title>Trouve Checkout</title>
          <meta property="og:title" content="Trouve Checkout" />
          <meta
            name="description"
            content="Secure Checkout for Your Trouve Thrift Purchase"
          />
          <meta
            property="og:description"
            content="Secure Checkout for Your Trouve Thrift Purchase"
          />
        </Helmet>
        <FlexContainer>
          <FlexItem marginAuto>
            <H1 style={{ marginBottom: '5px' }}>{APP_CHECKOUT_METHOD}</H1>
            <CustomerName>{customer.name}</CustomerName>
          </FlexItem>
          <FlexItem marginAuto alignRight>
            {!paid ? (
              <Due
                style={{
                  backgroundColor: overdue
                    ? 'var(--error--light)'
                    : 'var(--success--light)',
                  color: overdue ? 'var(--error)' : 'var(--success)',
                }}
              >
                {overdue ? 'Overdue' : 'Due Today'}
              </Due>
            ) : (
              <Paid>Paid</Paid>
            )}
          </FlexItem>
        </FlexContainer>
        {paid ? (
          <>
            <HR />
            <PaidSection>
              <P>
                Your card was charged successfully. Thanks for your purchase!
              </P>
            </PaidSection>
          </>
        ) : (
          <>
            <HR />
            <H2>Pay Now</H2>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                total={humanReadableDollars(
                  (productPricesSum + shippingPrice - appliedCredit) / 100,
                )}
                saleId={data._id}
                existingMethod={customer.existingMethod?.card}
                setPaid={setPaid}
              />
            </Elements>
            <Secure>
              <FiLock />
              Your card is securely processed via&nbsp;
              <a
                style={{ color: 'inherit' }}
                target="_blank"
                rel="noreferrer"
                href="https://stripe.com/"
              >
                Stripe
              </a>
              .
            </Secure>
          </>
        )}
        <HR />
        <H2>Sale Details</H2>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Item</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Amount
              </TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow
                key={product._id}
                style={{ backgroundColor: 'transparent' }}
              >
                <TableItem>{product.name}</TableItem>
                <TableItem style={{ textAlign: 'right' }}>
                  {humanReadableDollars(product.price / 100)}
                </TableItem>
              </TableRow>
            ))}
            {shippingPrice > 0 && (
              <>
                <TableRow
                  style={{ backgroundColor: 'var(--gray--background)' }}
                >
                  <TableItem style={{ fontWeight: 600 }}>Subtotal</TableItem>
                  <TableItem style={{ textAlign: 'right', fontWeight: 600 }}>
                    {humanReadableDollars(productPricesSum / 100)}
                  </TableItem>
                </TableRow>
                <TableRow style={{ backgroundColor: 'transparent' }}>
                  <TableItem>Shipping</TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    {humanReadableDollars(shippingPrice / 100)}
                  </TableItem>
                </TableRow>
              </>
            )}
            {appliedCredit > 0 && (
              <TableRow style={{ backgroundColor: 'transparent' }}>
                <TableItem>Applied Credit</TableItem>
                <TableItem style={{ textAlign: 'right' }}>
                  -{humanReadableDollars(appliedCredit / 100)}
                </TableItem>
              </TableRow>
            )}
            <TableRow style={{ backgroundColor: 'var(--gray--background)' }}>
              <TableItem style={{ fontWeight: 600 }}>Total</TableItem>
              <TableItem style={{ textAlign: 'right', fontWeight: 600 }}>
                {humanReadableDollars(
                  (productPricesSum + shippingPrice - appliedCredit) / 100,
                )}
              </TableItem>
            </TableRow>
          </TableBody>
        </Table>
      </Container>
    </Wrapper>
  );
};

export default Checkout;

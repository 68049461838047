import PropTypes from 'prop-types';
import { humanReadableDollars } from '../utils/helpers';
import { Button, TableItem, TableRow } from './elements/Elements';

const WriteOffsRow = ({ writeOff }) => {
  const { _id: id, createdAt, notes, totalCost } = writeOff;

  return (
    <>
      <TableRow key={id}>
        <TableItem>
          {new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }).format(new Date(createdAt))}
        </TableItem>
        <TableItem>{notes}</TableItem>
        <TableItem
          style={{
            textAlign: 'right',
          }}
        >
          {humanReadableDollars(totalCost / 100)}
        </TableItem>
        <TableItem style={{ textAlign: 'right' }}>
          <Button tiny to={`/write-offs/${id}`}>
            View
          </Button>
        </TableItem>
      </TableRow>
    </>
  );
};

WriteOffsRow.propTypes = {
  writeOff: PropTypes.object.isRequired,
};

export default WriteOffsRow;

import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { NavLink, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  FiBook,
  FiFolderMinus,
  FiGrid,
  FiLogIn,
  FiLogOut,
  FiShoppingBag,
  FiPackage,
  FiUsers,
  FiSettings,
} from 'react-icons/fi';
import useAuth from '../stores/auth';
import Favicon from '../assets/img/favicon.png';

const AppWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  min-height: -webkit-fill-available;
`;

const MainWrapper = styled.div`
  position: relative;
  flex: 1;
  background-color: var(--white);
  overflow-y: hidden;
  padding: var(--space);

  & > * {
    margin: 0 auto;
  }

  form {
    max-width: var(--narrow-max-width);
  }
`;

const Account = styled.div`
  display: flex;
  padding: var(--space);
`;

const Icon = styled.img`
  background-color: white;
  width: 28px;
  height: 28px;
  border: 2px solid var(--gray);
  border-radius: 100%;
`;

const Name = styled(NavLink)`
  font-size: 1.1rem;
  font-weight: 500;
  margin: auto 0 auto 10px;
  color: var(--white);
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--blue);
  position: sticky;
  top: 0;
  min-width: 260px;
  height: 100vh;
  padding-bottom: 1rem;
  border-right: 3px solid var(--dark-blue);
`;

const Nav = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const NavItems = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const NavItem = styled.li`
  display: block;
`;

const NavItemLink = styled(NavLink)`
  display: flex;
  width: 100%;
  padding: 1rem var(--space);
  color: var(--white);

  &.active,
  &:active {
    background-color: var(--dark-blue);
  }

  svg {
    margin: 1px 10px 0 0;
    color: var(--white);
    fill: var(--gray);
    stroke-width: 1.5px;
  }
`;

const NavItemButton = styled.button`
  display: flex;
  width: 100%;
  padding: 1rem var(--space);
  background-color: transparent;
  border: none;
  text-align: left;
  color: var(--white);

  &:active {
    background-color: var(--dark-blue);
  }

  svg {
    margin: 1px 10px 0 0;
    color: var(--white);
    fill: var(--gray);
    stroke-width: 1.5px;
  }
`;

const AppLayout = ({ children }) => {
  const { token, removeToken } = useAuth();
  const { push } = useHistory();

  return (
    <>
      <Helmet>
        <title>Trouve Management</title>
        <meta property="og:title" content="Trouve Management" />
        <meta
          name="description"
          content="Trouve internal inventory and accounting managment application"
        />
        <meta
          property="og:description"
          content="Trouve internal inventory and accounting managment application"
        />
      </Helmet>
      <AppWrapper>
        <Sidebar>
          <Account>
            <Icon src={Favicon} alt="logo icon" />
            <Name to="/">Trouve Thrift</Name>
          </Account>
          <Nav>
            <NavItems>
              <NavItem>
                <NavItemLink exact to="/">
                  <FiGrid />
                  Dashboard
                </NavItemLink>
              </NavItem>
              <NavItem>
                <NavItemLink to="/products">
                  <FiPackage />
                  Products
                </NavItemLink>
              </NavItem>
              <NavItem>
                <NavItemLink to="/sales">
                  <FiShoppingBag />
                  Sales
                </NavItemLink>
              </NavItem>
              <NavItem>
                <NavItemLink to="/expenses">
                  <FiBook />
                  Expenses
                </NavItemLink>
              </NavItem>
              <NavItem>
                <NavItemLink to="/customers">
                  <FiUsers />
                  Customers
                </NavItemLink>
              </NavItem>
              <NavItem>
                <NavItemLink to="/write-offs">
                  <FiFolderMinus />
                  Write Offs
                </NavItemLink>
              </NavItem>
              <li style={{ flex: 1 }} />
              <NavItem>
                <NavItemLink to="/settings">
                  <FiSettings />
                  Settings
                </NavItemLink>
              </NavItem>
              <NavItem>
                {token ? (
                  <NavItemButton
                    type="button"
                    onClick={() => {
                      removeToken();
                      push('/auth');
                    }}
                  >
                    <FiLogOut />
                    Sign Out
                  </NavItemButton>
                ) : (
                  <NavItemLink to="/auth">
                    <FiLogIn />
                    Sign In
                  </NavItemLink>
                )}
              </NavItem>
            </NavItems>
          </Nav>
        </Sidebar>
        <MainWrapper>{children}</MainWrapper>
      </AppWrapper>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;

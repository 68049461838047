import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { throttle, isEmpty } from 'lodash';
import styled from 'styled-components/macro';
import { humanReadableDollars } from '../utils/helpers';
import Client from '../utils/network';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  Input,
  LoadingIndicator,
  Table,
  TableBody,
  TableHead,
  TableHeadItem,
  TableItem,
  TableRow,
} from './elements/Elements';
import useFilters from '../stores/filters';
import TableSkeleton from './skeletons/TableSkeleton';

const MAX_RESULTS = 50;
const SEARCH_THROTTLE_WAIT = 1000;

const Filters = styled.div``;

const Filter = styled.div`
  display: inline-block;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const FilterCheckbox = styled.input`
  margin: 1px 5px 0 0;
`;

const FilterLabel = styled.label`
  display: flex;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--gray);
`;

const Products = () => {
  const [products, setProducts] = useState();
  const [searching, setSearching] = useState();
  const { productFilters, setProductFilters } = useFilters();
  const [filters, setFilters] = useState(productFilters);

  const search = async (searchTerm = '') => {
    if ([1, 2].includes(searchTerm.length)) return;

    setSearching(true);

    try {
      setProducts(
        await Client.get(
          `/getProducts?search=${searchTerm}&limit=${MAX_RESULTS}&unsold=${
            filters.unsold || ''
          }&sold=${filters.sold || ''}&writtenOff=${filters.writtenOff || ''}`,
        ),
      );
    } catch (error) {
      toast.error(error);
    }

    setSearching(false);
  };

  const throttledSearch = useCallback(
    throttle((searchTerm) => {
      search(searchTerm);
    }, SEARCH_THROTTLE_WAIT),
    [],
  );

  useEffect(() => {
    if (isEmpty(filters)) return;

    search('');
    setProductFilters(filters);
  }, [filters]);

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Products</H1>
        </FlexItem>
        <FlexItem marginAuto alignRight>
          <Button to="/products/create">Create Product</Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem marginAuto style={{ maxWidth: '400px' }}>
          <Input
            style={{ maxWidth: '400px' }}
            autoFocus
            name="search"
            type="text"
            placeholder="Type to search"
            onChange={({ target: { value } }) => throttledSearch(value)}
          />
        </FlexItem>
        <FlexItem
          marginAuto
          style={{
            position: 'relative',
            maxWidth: '50px',
          }}
        >
          {products && searching && <LoadingIndicator small />}
        </FlexItem>
        <FlexItem marginAuto alignRight>
          <Filters>
            <Filter>
              <FilterLabel
                htmlFor="unsold"
                style={{ color: filters.unsold && 'var(--black)' }}
              >
                <FilterCheckbox
                  id="unsold"
                  type="checkbox"
                  checked={filters.unsold}
                  onChange={({ target: { checked } }) =>
                    setFilters({ ...filters, unsold: checked })
                  }
                />
                Unsold
              </FilterLabel>
            </Filter>
            <Filter>
              <FilterLabel
                htmlFor="sold"
                style={{ color: filters.sold && 'var(--black)' }}
              >
                <FilterCheckbox
                  id="sold"
                  type="checkbox"
                  checked={filters.sold}
                  onChange={({ target: { checked } }) =>
                    setFilters({ ...filters, sold: checked })
                  }
                />
                Sold
              </FilterLabel>
            </Filter>
            <Filter>
              <FilterLabel
                htmlFor="writtenOff"
                style={{ color: filters.writtenOff && 'var(--black)' }}
              >
                <FilterCheckbox
                  id="writtenOff"
                  type="checkbox"
                  checked={filters.writtenOff}
                  onChange={({ target: { checked } }) =>
                    setFilters({ ...filters, writtenOff: checked })
                  }
                />
                Written Off
              </FilterLabel>
            </Filter>
          </Filters>
        </FlexItem>
      </FlexContainer>
      {products ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Name</TableHeadItem>
              <TableHeadItem>Notes</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>Cost</TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Price
              </TableHeadItem>
              <TableHeadItem style={{ textAlign: 'right' }}>
                Profit
              </TableHeadItem>
              <TableHeadItem />
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(
              ({
                _id: id,
                name,
                notes,
                cost,
                salesTax,
                price,
                sale,
                writeOff,
              }) => (
                <TableRow key={id}>
                  <TableItem>{name}</TableItem>
                  <TableItem>{notes || ''}</TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    {humanReadableDollars((cost + salesTax) / 100)}
                  </TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    {humanReadableDollars(price / 100)}
                  </TableItem>
                  <TableItem
                    style={{
                      textAlign: 'right',
                      color: price - cost - salesTax < 0 && 'var(--error)',
                    }}
                  >
                    {(sale || writeOff) &&
                      humanReadableDollars((price - cost - salesTax) / 100)}
                  </TableItem>
                  <TableItem style={{ textAlign: 'right' }}>
                    <Button tiny to={`/products/${id}`}>
                      {sale || writeOff ? 'View' : 'Edit'}
                    </Button>
                  </TableItem>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
};

export default Products;

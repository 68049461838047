import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Client from '../utils/network';
import {
  Input,
  FormikField,
  InputGroup,
  FlexItem,
  Button,
  Select,
  P,
} from './elements/Elements';
import { EXPENSE_CATEGORIES } from '../constants';
import { formatMoney, humanReadableDollars } from '../utils/helpers';
import ResourceSection from './ResourceSection';

const ExpenseForm = ({ data }) => {
  const { push } = useHistory();

  return (
    <ResourceSection>
      <Formik
        initialValues={{
          description: data?.description || '',
          cost: data?.cost ? humanReadableDollars(data.cost / 100) : '',
          category: data?.category || 'Select One',
          merchant: data?.merchant || '',
        }}
        onSubmit={async (values) => {
          try {
            const { method, path, successPastVerb } = {
              method: data ? 'put' : 'post',
              path: data ? `/updateExpense?id=${data._id}` : '/createExpense',
              successPastVerb: data ? 'updated' : 'created',
            };

            await Client[method](path, values);

            toast.success(`Expense successfully ${successPastVerb}.`);
            push('/expenses');
          } catch (error) {
            toast.error(error);
          }
        }}
        validationSchema={() =>
          Yup.object().shape({
            description: Yup.string().required('Please enter a description.'),
            cost: Yup.string().required('Please enter an amount.'),
            category: Yup.string()
              .oneOf(EXPENSE_CATEGORIES, 'Please choose a category.')
              .required('Please choose a category.'),
            merchant: Yup.string().required('Please enter a merchant.'),
          })
        }
      >
        {(formProps) => {
          return (
            <Form>
              <FormikField
                {...formProps}
                name="description"
                as={Input}
                type="text"
                placeholder="Description"
                label="Description"
                disabled={data?.generated}
              />
              <InputGroup>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    as={Input}
                    placeholder="Merchant"
                    type="text"
                    name="merchant"
                    label="Merchant"
                    disabled={data?.generated}
                  />
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    as={Select}
                    name="category"
                    label="Category"
                    disabled={data?.generated}
                  >
                    <option disabled value="Select One">
                      Select One
                    </option>
                    {EXPENSE_CATEGORIES.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </FormikField>
                </FlexItem>
                <FlexItem>
                  <FormikField
                    {...formProps}
                    name="cost"
                    as={Input}
                    placeholder="$0.00"
                    label="Cost (Including Tax)"
                    type="text"
                    onBlur={() =>
                      formProps.setFieldValue(
                        'cost',
                        formatMoney(formProps.values.cost),
                        false,
                      )
                    }
                    onClick={() => {
                      document.execCommand('selectall', null, false);
                    }}
                    disabled={data?.generated}
                  />
                </FlexItem>
              </InputGroup>
              {!data?.generated ? (
                <Button loading={formProps.isSubmitting} type="submit">
                  Save Expense
                </Button>
              ) : (
                <P>
                  This expense was system-generated and cannot be edited or
                  deleted.
                </P>
              )}
            </Form>
          );
        }}
      </Formik>
    </ResourceSection>
  );
};

ExpenseForm.propTypes = {
  data: PropTypes.object,
};

ExpenseForm.defaultProps = {
  data: null,
};

export default ExpenseForm;

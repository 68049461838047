import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GoogleLogin } from 'react-google-login';
import client from '../utils/network';
import { H1, HR, LoadingIndicator, P } from './elements/Elements';
import { GOOGLE_CLIENT_ID } from '../constants';
import useAuth from '../stores/auth';

const Authentication = () => {
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const { setToken } = useAuth();

  const onSuccess = async ({ tokenId }) => {
    try {
      setLoading(true);

      const { jwt } = await client.post('/authWithGoogle', {
        tokenId,
      });

      setToken(jwt);

      setLoading(false);
      toast.success('Signed in successfully.');
      push('/');
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const onFailure = (error) => {
    toast.error(error);
    setLoading(false);
  };

  return (
    <>
      <H1>Sign In</H1>
      <P style={{ color: 'var(--gray)', marginTop: '1rem' }}>
        Trouve Inventory and Accounting Management
      </P>
      <HR />
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy="single_host_origin"
        responseType="token"
      />
      {loading && <LoadingIndicator />}
    </>
  );
};

export default Authentication;

const prod = "development" === 'production';
const APP_CHECKOUT_METHOD = 'Trouve Checkout';
const APP_CREDIT_METHOD = 'Trouve Credit';

module.exports = {
  GOOGLE_CLIENT_ID:
  '840634624071-mjdq0qvl87im5juu5rh8ankatdsaauu7.apps.googleusercontent.com',
  STRIPE_PK: prod ?
  'pk_live_51IN3gIJF3r7yqgQt6LynrhmJc6Ay3ZavK3nEYo1SJURNbtS85cw8p6fWHIhQctF2aPq1OenR4EuhUazWUSVqjrcQ00M9ckWTMX' :
  'pk_test_51IN3gIJF3r7yqgQtwW1BSsa6dBaqdH2HahWCnXbX2c2tzBLcgLZ7WOVN3zL1fdcGsVFSd29npf2IGyoOyzXKnv6p009EZub85Z',
  TOKEN_STORAGE_KEY: '_trouve_jwt',
  PRODUCT_FILTERS_STORAGE_KEY: '_trouve_product_filters',
  API_BASE_URL: '/api',
  CATEGORIES: [
  'Outerwear',
  'Tops',
  'Bottoms',
  'Shoes',
  'Housewares',
  'Accessories',
  'Kids',
  'Toys',
  'Books',
  'Jumpsuits',
  'Dresses'],

  SALES_TAX_RATES: [
  { label: 'Pennsylvania (6%)', value: 0.06 },
  { label: 'No Sales Tax', value: 0 }],

  PAYMENT_METHODS: [
  APP_CHECKOUT_METHOD,
  APP_CREDIT_METHOD,
  'Venmo',
  'Cash',
  'Paypal'],

  EXPENSE_CATEGORIES: [
  'Packing Materials',
  'Marketing',
  'Operations',
  'Shipping',
  'Payment Processing'],

  APP_CHECKOUT_METHOD,
  APP_CREDIT_METHOD,
  BUSINESS_HOME_PAGE: 'https://instagram.com/trouve.thrift',
  NANOID_ALPHABET:
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
  SENTRY_DSN_API:
  'https://72d0dd7e0bed4086a129b9826549606f@o557419.ingest.sentry.io/5689651',
  SENTRY_DSN_CLIENT: '' };
import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const StyledTableItem = styled.td`
  max-width: 250px;
  border-top: 1px solid var(--gray--border);
  padding: 1rem 1.25rem;
`;

const TableItem = ({ children, ...props }) => {
  return <StyledTableItem {...props}>{children}</StyledTableItem>;
};

TableItem.propTypes = {
  children: PropTypes.node,
};

TableItem.defaultProps = {
  children: null,
};

export default TableItem;

import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import KeyboardOnlyOutlines from '@moxy/react-keyboard-only-outlines';
import App from './App';
import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/global.css';

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      transition={Slide}
    />
    <HelmetProvider>
      <KeyboardOnlyOutlines>
        <App />
      </KeyboardOnlyOutlines>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

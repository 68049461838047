import create from 'zustand';
import { TOKEN_STORAGE_KEY } from '../constants';

const useAuth = create((set) => ({
  token: JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY)),
  setToken: (token) => {
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(token));
    set(() => ({ token }));
  },
  removeToken: () => {
    localStorage.setItem(TOKEN_STORAGE_KEY, null);
    set(() => ({ token: null }));
  },
}));

export default useAuth;

import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import { FiX } from 'react-icons/fi';
import { throttle } from 'lodash';
import Client from '../utils/network';
import { Input, LoadingIndicator } from './elements/Elements';

const MAX_RESULTS = 10;
const SEARCH_THROTTLE_WAIT = 1000;

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Results = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ItemWrapper = styled.li`
  margin-top: 10px;
`;

const Item = styled.button`
  display: flex;
  width: 100%;
  background-color: var(--gray--background);
  border: none;
  padding: 1rem;
  border-radius: var(--border-radius);
  text-align: left;
  outline: none;

  &:active,
  &:focus {
    box-shadow: inset 0 0 0 2px var(--blue);
  }
`;

const Name = styled.span`
  display: block;
  font-weight: 500;
`;

const Category = styled.span`
  display: block;
  margin: auto 0;
  font-size: 0.9rem;
  color: var(--gray);
`;

const CloseWrapper = styled.div`
  position: fixed;
  top: var(--space);
  right: var(--space);
  background-color: var(--gray--background);
  border-radius: 100%;
  height: 75px;
  width: 75px;

  &:active,
  &:focus-within {
    box-shadow: inset 0 0 0 2px var(--blue);
  }

  @media (max-width: 1200px) {
    bottom: var(--space);
    top: initial;
  }
`;

const Close = styled.button`
  position: absolute;
  height: 75px;
  width: 75px;
  border: none;
  border-radius: 100%;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;

  svg {
    display: block;
    font-size: 1.5rem;
    stroke-width: 1.5px;
    opacity: 0.5;
    margin: 0 auto;
  }

  span {
    display: block;
    color: var(--gray);
    font-size: 0.75rem;
  }
`;

const ProductSelect = ({ close }) => {
  const { values, setFieldValue } = useFormikContext();
  const [products, setProducts] = useState();
  const [searching, setSearching] = useState();

  const search = async (searchTerm = '') => {
    if ([1, 2].includes(searchTerm.length)) return;

    setSearching(true);

    try {
      setProducts(
        await Client.get(
          `/getProducts?search=${searchTerm}&limit=${MAX_RESULTS}&unsold=true`,
        ).then((data) =>
          data.filter(
            (product) =>
              !values.products.map(({ _id: id }) => id).includes(product._id),
          ),
        ),
      );
    } catch (error) {
      toast.error(error);
    }

    setSearching(false);
  };

  const throttledSearch = useCallback(
    throttle((searchTerm) => {
      search(searchTerm);
    }, SEARCH_THROTTLE_WAIT),
    [],
  );

  useEffect(() => {
    search('');
  }, []);

  return (
    <Wrapper>
      <Input
        autoFocus
        name="search"
        type="text"
        placeholder="Type to search"
        onChange={({ target: { value } }) => throttledSearch(value)}
      />
      {products ? (
        <Results>
          {products.map((product) => (
            <ItemWrapper key={product._id}>
              <Item
                type="button"
                onClick={() => {
                  setFieldValue('products', [...values.products, product]);
                  close();
                }}
              >
                <Name style={{ flex: 1 }}>{product.name}</Name>
                <Category>{product.category}</Category>
              </Item>
            </ItemWrapper>
          ))}
        </Results>
      ) : (
        <LoadingIndicator />
      )}
      <CloseWrapper>
        <Close type="button" onClick={() => close()}>
          <FiX />
          <span>Esc</span>
        </Close>
      </CloseWrapper>
      {products && searching && <LoadingIndicator />}
    </Wrapper>
  );
};

ProductSelect.propTypes = {
  close: PropTypes.func.isRequired,
};

export default ProductSelect;

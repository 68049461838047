import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  subMonths,
  startOfMonth,
  endOfMonth,
  subYears,
  startOfYear,
  endOfYear,
} from 'date-fns';
import {
  Button,
  FlexContainer,
  FlexItem,
  H1,
  LoadingIndicator,
  Select,
} from './elements/Elements';
import Client from '../utils/network';
import { humanReadableDollars } from '../utils/helpers';
import MetricsSkeleton from './skeletons/MetricsSkeleton';
import Metrics from './Metrics';
import Metric from './Metric';

const DATE_RANGES = {
  allTime: {
    start: new Date(2000, 1, 1),
    end: new Date(3000, 1, 1),
  },
  thisMonth: {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  },
  lastMonth: {
    start: subMonths(startOfMonth(new Date()), 1),
    end: subMonths(endOfMonth(new Date()), 1),
  },
  thisYear: {
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  },
  lastYear: {
    start: subYears(startOfYear(new Date()), 1),
    end: subYears(endOfYear(new Date()), 1),
  },
};

const DATE_RANGES_OPTIONS = [
  { label: 'All Time', value: 'allTime' },
  { label: 'This Month', value: 'thisMonth' },
  { label: 'Last Month', value: 'lastMonth' },
  { label: 'This Year', value: 'thisYear' },
  { label: 'Last Year', value: 'lastYear' },
];

const DEFAULT_DATE_RANGE = 'allTime';

const Dashboard = () => {
  const [metrics, setMetrics] = useState();
  const [loadingPeriod, setLoadingPeriod] = useState();
  const [dateRange, setDateRange] = useState(DATE_RANGES[DEFAULT_DATE_RANGE]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingPeriod(true);

        const { start, end } = dateRange;

        const rangeMetrics = await Client.get(
          `/getMetrics?start=${start}&end=${end}`,
        );

        setMetrics(
          rangeMetrics.map(({ label, value, type }) => ({
            label,
            type,
            value: `${value}`,
          })),
        );
        setLoadingPeriod(false);
      } catch (error) {
        setLoadingPeriod(false);
        toast.error(error);
      }
    })();
  }, [dateRange]);

  return (
    <>
      <FlexContainer style={{ marginBottom: '2rem' }}>
        <FlexItem>
          <H1>Dashboard</H1>
        </FlexItem>
        <FlexItem marginAuto>
          <FlexContainer style={{ justifyContent: 'flex-end' }}>
            <Button to="/sales/create">Create Sale</Button>
            <Button style={{ marginLeft: '10px' }} to="/products/create">
              Create Product
            </Button>
          </FlexContainer>
        </FlexItem>
      </FlexContainer>
      <FlexContainer
        style={{ marginBottom: '2rem', marginLeft: '0', maxWidth: '280px' }}
      >
        <FlexItem>
          <Select
            id="timeframe"
            onChange={({ target: { value } }) => {
              setDateRange(DATE_RANGES[value]);
            }}
          >
            <option disabled value="Select One">
              Select One
            </option>
            {DATE_RANGES_OPTIONS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </FlexItem>
        <FlexItem
          marginAuto
          style={{
            position: 'relative',
            maxWidth: '50px',
          }}
        >
          {metrics && loadingPeriod && <LoadingIndicator small />}
        </FlexItem>
      </FlexContainer>
      {metrics ? (
        <Metrics>
          {metrics.map(({ label, value, type }) => (
            <Metric
              key={label}
              value={
                (type === 'COUNT' && value) ||
                (type === 'PERCENT' && `${value}%`) ||
                (type === 'CURRENCY' && (
                  <span style={{ color: value < 0 && 'var(--error)' }}>
                    {humanReadableDollars(value / 100)}
                  </span>
                ))
              }
              label={label}
            />
          ))}
        </Metrics>
      ) : (
        <MetricsSkeleton />
      )}
    </>
  );
};

export default Dashboard;
